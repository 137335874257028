import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Header from '../components/Header';
import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';
import Layout from '~/src/components/Layout';
import clients from '~/static/clients.png';

export default ({ posts, transition, data, pageContext }) => (
  <Layout>
    <Helmet title="Raul Perte | Side Projects" />
    <article id="content">
      <div>
        <Header simple>
          <h1>Side Projects</h1>
        </Header>
        <Block align="right" style={{ marginTop: '1%' }}>
          <h3>WeatherKit</h3>
          <p>A simple, elegant iOS weather application. Built with Swift.</p>
          <p>
            <a href="https://weatherkit.org">
              
            </a>
          </p>
        </Block>
      </div>
    </article>
  </Layout>
);
